import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { addTrainingTime, auth } from "../firebase";
import Button from "../components/Button";
import Modal from "../components/Modal";
import Dropdown from "../components/Dropdown";

const StripeCardElement = styled(CardElement)({
  // height: '40px',
  width: "300px",
  padding: "10px 12px",
  color: "#32325d",
  backgroundColor: "white",
  borderRadius: "4px",
  border: "1px solid transparent",
});

export default function TrainingTime({ account, user }) {
  const [open, setOpen] = useState(false);
  const [seconds, setSeconds] = useState(100);
  const stripe = useStripe();
  const elements = useElements();

  const items = [
    {
      key: 100,
      value: "Add 100",
    },
    { key: 500, value: "Add 500" },
    { key: 1000, value: "Add 1000" },
  ];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    addTrainingTime(auth.currentUser.uid, seconds, account);
    setOpen(false);
  };

  console.log("Seconds", seconds);

  return !user ? (
    "Loading.."
  ) : (
    <div className="bg-text-primary p-5 py-3 mb-4 rounded-xl flex justify-center items-center">
      <span className="text-base text-white font-normal">
        Training Time:&nbsp;
      </span>
      <span className="text-base text-white font-semibold">
        {account ? account?.training_time_left : 0}
      </span>
      <Button
        text="Add Time"
        style="primary"
        type="submit"
        moreStyles="ml-3"
        onClick={handleOpen}
      />

      <Modal
        open={open}
        handleClose={handleClose}
        title="Add Training Time"
        moreBoxStyles="!w-full sm:max-w-[500px]"
        content={
          <form onSubmit={handleSubmit} className="w-full">
            <Dropdown
              // icon={<ClockIcon />}
              items={items}
              label="Add Training time in seconds"
              moreStylesBox="!bg-blue-900"
              moreStylesWrapper="mb-3"
              moreStyleItems="!bg-blue-900"
              onChange={(value) => setSeconds(value.key)}
            />

            <StripeCardElement
              options={{
                style: {
                  base: {
                    color: "#fff",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  },
                },
              }}
              className="!w-full items-center !bg-blue-900 !text-xs placeholder:!text-[#8F959F] !text-white !py-4 !rounded-lg "
            />
            <div className="flex flex-row-reverse">
              <Button
                text={`Add ${seconds} Seconds`}
                style="primary"
                type="submit"
                disabled={!stripe}
                onClick={handleSubmit}
                moreStyles="my-5"
              />
            </div>
          </form>
        }
      />
    </div>
  );
}
