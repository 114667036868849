import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { ref, update } from "firebase/database";
import Button from "./Button";

const StyledCard = styled(Card)({
  minWidth: 275,
  margin: 16,
  backgroundColor: "#424242", // dark grey color
});

const StatusTypography = styled(Typography)({
  marginBottom: 16,
  color: "#ffffff", // white color
});

function Socket({ data, account }) {
  const uid = auth.currentUser?.uid;
  const [lastMessage, setLastMessage] = useState(null);
  const [isTraining, setIsTraining] = useState(false);
  const [isStopping, setIsStopping] = useState(false);

  const [ws, setWs] = useState(null);

  let elapsed_time = 0;
  useEffect(() => {
    if (ws) {
      ws.onmessage = (message) => {
        setLastMessage(JSON.parse(message.data));
        let object = JSON.parse(message.data);
        elapsed_time =
          account?.training_time_left - object.message.training_time;
        if (elapsed_time <= -1) {
        }
        update(ref(db, "account/" + uid), {
          training_time_left: elapsed_time,
        });
      };
      ws.onclose = () => {
        setIsTraining(false);
        setIsStopping(false);
      };
    }
  }, [ws]);

  const startTraining = () => {
    if (account?.training_time_left <= 0) return;
    if (uid && !isTraining) {
      let newWs = new WebSocket(`wss://api.arcangelai.com:8765/${uid}`);
      newWs.onopen = () => {
        console.log("WebSocket connection opened");
        newWs.send(JSON.stringify(data));
      };
      setWs(newWs);
      setIsTraining(true);
    }
  };

  const stopTraining = () => {
    if (ws && isTraining && !isStopping) {
      ws.close();
      setIsStopping(true);
    }
  };

  return (
    <div>
      <div className="flex items-center mb-3">
        <Button
          text={isTraining ? "Training..." : "Train"}
          style="primary"
          disabled={
            data.length <= 0 || isTraining || account.training_time_left <= 0
          }
          onClick={startTraining}
          moreStyles="mr-3 text-xs py-[7px]"
        />
        {!isTraining ? null : (
          <Button
            text={
              <>
                {isStopping ? "Stopping, please wait..." : "Stop Training"}
                {isStopping && <CircularProgress size={24} />}
              </>
            }
            style="error"
            onClick={isStopping ? null : stopTraining}
          moreStyles="mr-3 text-xs py-[7px]"
          />
        )}
      </div>
      <h6 className="text-white mb-5 text-md font-semibold">
        Connection status: {isTraining ? "Open" : "Closed"}
      </h6>
      {lastMessage ? (
        <div>
          <p className="text-white font-normal">
            Time: {lastMessage.message.training_time}
          </p>
          <p className="text-white font-normal">
            Current Epoch: {lastMessage.message.current_epoch}
          </p>
          <p className="text-white font-normal">
            Total Epochs: {lastMessage.message.total_epoch}
          </p>
          <p className="text-white font-normal">
            Iterations: {lastMessage.message.iterations}
          </p>
          <p className="text-white font-normal">
            Loss: {lastMessage.message.loss}
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default Socket;
