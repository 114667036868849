import { Container, Grid, Box, Paper, Typography } from "@mui/material";
import TrainingData from "../components/training-data";
import TestModelChat from "../components/TestModelChat";
import Socket from "../components/Socket";
import { useState } from "react";
import FileUploader from "../modules/fileUploader";
import TrainingTimeMeter from "../modules/TrainingTime";
import { auth } from "../firebase";

export default function Home({ user, account }) {
  const [data, setData] = useState([]);

  return (
    <Container maxWidth="md" sx={{ mt: 3 }}>
      <span style={{ color: "white", fontSize: 12 }}>
        UID: {auth.currentUser.uid}
      </span>
      <TrainingTimeMeter account={account} user={user} />
      <div className="flex flex-col tablet:grid grid-cols-2 gap-4">
        {/* Top Row */}
        <div className="bg-text-primary rounded-xl overflow-hidden">
          <div className="bg-blue-900 py-2 px-5">
            <h6 className="text-base  font-semibold text-white">
              Test API Call
            </h6>
          </div>
          <div className="text-white p-4 text-sm">
            <TestModelChat />
          </div>
        </div>
        <div className="bg-text-primary rounded-xl overflow-hidden">
          <div className="bg-blue-900 py-2 px-5">
            <h6 className="text-base  font-semibold text-white">Training</h6>
          </div>
          <div className="text-white p-4 text-sm">
            <Socket data={data} account={account} />
          </div>
        </div>

        {/* Bottom Row */}
        <div className="col-span-2">
          <div className="bg-text-primary rounded-xl overflow-hidden">
            <div className="bg-blue-900 py-2 px-5 flex items-center justify-between">
              <h6 className="text-base  font-semibold text-white">I/O Data</h6>
              <FileUploader setData={setData} data={data} />
            </div>
            <div className="flex flex-1 text-white p-4">
              <TrainingData data={data} setData={setData} />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
