import { useState } from "react";
import { Box, TextField, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { grey } from "@mui/material/colors";
import Button from "./Button";
import Input from "./Input";

export default function TrainingData({ data, setData }) {
  const [isChanged, setIsChanged] = useState(false);
  const [limit, setLimit] = useState(250000); // Set initial limit to 40, this value can be changed per user later
  const [loadmore, setLoadmore] = useState(5);
  const handleInputChange = (index, type, event) => {
    const newData = [...data];
    newData[index][type === "input" ? 0 : 1] = event.target.value;
    setData(newData);
    setIsChanged(true);
  };

  const addRow = () => {
    if (data.length < limit) {
      setData([["", ""], ...data]);
      setIsChanged(true);
    } else {
      alert(`You've reached the limit of ${limit} rows.`);
    }
  };

  const cancelRow = () => {
    if (data.length > 0) {
      const newData = [...data];
      newData.shift();
      setData(newData);
    }
    setIsChanged(false);
  };

  const deleteRow = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const saveData = () => {
    if (data.length > 0 && (data[0][0] === "" || data[0][1] === "")) {
      alert("Both input and output fields must be filled!");
    } else {
      console.log(data);
      setIsChanged(false);
    }
  };

  return (
    <div className="w-full">
      {data.slice(0, loadmore).map((row, index) => (
        <div className="flex items-center gap-3 mb-4">
          <Input
            style="dark"
            type="text"
            onChange={(event) => handleInputChange(index, "input", event)}
            placeholder="Enter your input..."
            value={row[0]}
            inputId="input"
            name="input"
            moreStylesWrapper="!w-full"
          />
          <Input
            style="dark"
            type="text"
            onChange={(event) => handleInputChange(index, "output", event)}
            placeholder="Enter your output..."
            value={row[1]}
            inputId="output"
            name="output"
            moreStylesWrapper="!w-full"
          />
          <Button
            text={<DeleteIcon />}
            style="error"
            moreStyles="mr-3 !w-fit"
            disabled={isChanged}
            onClick={() => deleteRow(index)}
          />
        </div>
      ))}
      <div className="flex flex-wrap items-center">
        {isChanged ? (
          <>
            <Button
              text="Save"
              style="primary"
              onClick={saveData}
              moreStyles="mr-3"
            />
            <Button text="Cancel" style="error" onClick={cancelRow} />
          </>
        ) : (
          <Button
            text="+"
            style="primary"
            moreStyles="w-fit !text-lg"
            disabled={isChanged}
            onClick={addRow}
          />
        )}
      </div>
      {data.length > 10 ? (
        <Button
          endIcon={data.length - loadmore}
          onClick={() => setLoadmore((more) => more + 10)}
        >
          Loadmore
        </Button>
      ) : null}
    </div>
  );
}
