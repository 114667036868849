const bgClassnames = {
  primary:
    "flex items-center justify-center bg-blue-500 hover:bg-hover-primary focus:shadow-primary px-5 py-2.5 rounded-lg text-white text-sm",
  dark: "flex items-center justify-center bg-blue-900 hover:bg-hover-primary focus:shadow-primary px-5 py-2.5 rounded-lg text-white text-sm",
  error:
    "flex items-center justify-center bg-red-600 hover:bg-hover-primary focus:shadow-primary px-5 py-2.5 rounded-lg text-white text-sm",
};

const iconStyles = {
  primary: "flex items-center tablet:mr-3",
};

const Button = ({
  style = "primary",
  moreStyles,
  icon = null,
  iconMoreStyles,
  text = "",
  ariaLabel = "",
  disabled = false,
  onClick = () => {},
  ...otherProps
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${bgClassnames[style]} ${moreStyles} ${
        disabled && "opacity-50"
      }`}
      aria-label={ariaLabel || text}
      disabled={disabled}
      {...otherProps}
    >
      {icon ? (
        <div
          className={`${iconStyles[style]} ${
            iconMoreStyles ? iconMoreStyles : ""
          }`}
        >
          {icon}
        </div>
      ) : null}
      {text}
    </button>
  );
};

export default Button;
