import React from "react";

const wrapperStyle = {
  primary: "mb-3",
};
const inputStyles = {
  primary:
    "w-full flex items-center bg-text-primary text-xs placeholder:text-[#8F959F] text-white py-4 rounded-lg ",
  dark:
    "w-full flex items-center bg-blue-900 text-xs placeholder:text-[#8F959F] text-white py-4 rounded-lg ",
};
const iconStyles = {
  primary: "absolute inset-y-0 flex items-center pl-3 pointer-events-none",
};

const labelStyles = {
  primary: "text-white text-sm font-medium mb-2 block",
};

const Input = (props) => {
  const {
    disabled = false,
    leftIcon = null,
    type = "text",
    rightIcon = null,
    style,
    name = "",
    inputId = "",
    moreStyles = "",
    moreStylesWrapper = "",
    placeholder = "",
    onChange,
    label,
    moreLabelStyles,
    required = false,
    value,
    disabledText,
    rightAction,
    messageText,
    messageTextStyles,
  } = props;

  return (
    <div className={`${wrapperStyle[style]} ${moreStylesWrapper}`}>
      {label && (
        <label
          htmlFor={inputId}
          className={`${labelStyles[style]} ${moreLabelStyles}`}
        >
          {label} {required ? <span className="text-red-800">*</span> : ""}
        </label>
      )}
      <div class="relative">
        {leftIcon && <div class={`${iconStyles} left-2`}>{leftIcon}</div>}

        <input
          type={type}
          className={`${inputStyles[style]} ${moreStyles} ${
            rightIcon ? "pr-10" : leftIcon ? "pl-10" : "px-4"
          } disabled:bg-gray-300 `}
          id={inputId}
          placeholder={placeholder || ""}
          disabled={disabled}
          required={required}
          onChange={onChange}
          value={value}
          name={name}
        />

        {rightIcon && <div class={`${iconStyles} right-2`}>{rightIcon}</div>}
      </div>
    </div>
  );
};

export default Input;
