import React from "react";
import { AppBar, Toolbar, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import logo from "../img/logo.png";
import { logout } from "../firebase";
import ReadMeButton from "./Guide";
import Button from "./Button";

export default function TopMenu({ user }) {
  const handleLogout = () => {
    logout();
  };

  const Logo = styled("img")({
    height: 50,
    width: 50,
    marginRight: "16px",
  });

  return (
    <div className="bg-text-primary text-white px-8 py-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="h-12 w-12 mr-4" />
          <h6 className="text-xl font-semibold hidden tablet:block">
            Arc Training
          </h6>
        </div>
        <div className="flex items-center">
          <Button
            text="Sign out"
            style="dark"
            moreStyles="w-full mr-4"
            type="submit"
            onClick={handleLogout}
          />
          <ReadMeButton />
        </div>
      </div>
    </div>
  );
}
