import React from "react";
import Button from "../components/Button";

function FileUploader({ setData, data }) {
  const fileInputRef = React.useRef();
  const MAX_LIMIT = 250000;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (file.type !== "application/json") {
      alert("Only JSON files are allowed");
      return;
    }
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const json = JSON.parse(e.target.result);
        if (!Array.isArray(json)) {
          throw new Error(
            "Invalid file format: the JSON file should contain an array"
          );
        }
        if (json.length + data.length > MAX_LIMIT) {
          alert(`The total number of samples cannot exceed ${MAX_LIMIT}`);
          return;
        }
        if (
          !json.every(
            (item) =>
              Array.isArray(item) &&
              item.length === 2 &&
              item.every((i) => typeof i === "string")
          )
        ) {
          throw new Error(
            "Invalid file format: the JSON array should contain sub-arrays with exactly two string items"
          );
        }
        setData((prevData) => [...prevData, ...json]);
      } catch (error) {
        alert(error.message);
      }
    };
    reader.readAsText(file);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="flex items-center">
      <input
        ref={fileInputRef}
        type="file"
        accept=".json"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <Button
        text="Upload JSON"
        style="primary"
        onClick={handleClick}
        moreStyles="mr-3 py-[8px]"
      />
      <Button
        text="Clear"
        style="error"
        onClick={() => setData([])}
        moreStyles="mr-3 py-[8px]"
      />
    </div>
  );
}

export default FileUploader;
