// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, update } from "firebase/database";

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD9uEC_jahxWU8mFp6Z6X_E3xgmysD3JEY",
  authDomain: "arcangelai.firebaseapp.com",
  projectId: "arcangelai",
  storageBucket: "arcangelai.appspot.com",
  messagingSenderId: "718660747295",
  appId: "1:718660747295:web:bc264b3f6da93a2f9bd52a",
  measurementId: "G-6G059NF0J5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the auth service
export const auth = getAuth(app);
export const db = getDatabase(app);

// Create a function to sign up a user
async function signup(email, password) {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    return error.code;
  }
}

// Create a function to log in a user
async function login(email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    return error.code;
  }
}

// Logout
async function logout(){
  await signOut(auth);
}


async function addTrainingTime(uid, seconds, account){

  update(ref(db, 'account/' + uid),{
    uid: uid,
    training_time_left: account? seconds + account.training_time_left : seconds
  })
  .then(()=>{
    console.log("Time added")
    return "Time added"
  })
}



export { signup, login, logout, addTrainingTime };
