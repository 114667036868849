import React, { useState } from "react";
import { login, signup } from "../firebase";
import logo from "../img/logo.png";
import Input from "../components/Input";
import Button from "../components/Button";
import Checkbox from "../components/Checkbox";

export default function Auth() {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [serverResponse, setServerResponse] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword && !isLogin) {
      setServerResponse("Passwords do not match");
      return;
    }

    if (!acceptedTerms && !isLogin) {
      setServerResponse("You must accept the terms and conditions");
      return;
    }

    if (isLogin) {
      const response = await login(email, password);
      setServerResponse(
        typeof response === "string" ? response : "Logged in successfully"
      );
    } else {
      const response = await signup(email, password);
      setServerResponse(
        typeof response === "string" ? response : "Signed up successfully"
      );
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-primary px-3">
      <form onSubmit={handleSubmit}>
        <img src={logo} alt="logo" className="w-[75px] h-[75px] m-auto mb-2" />
        <h1 className="font-bold text-3xl max-w-[32rem] min-w-[10rem] text-white mt-4 mb-1 leading-10">
          ArcAngelAI&nbsp;
          <span className="text-blue-500">.</span>
        </h1>
        <p className="text-2xl max-w-[32rem] text-white mt-1 mb-5 leading-10">
          Custom AI model training playground&nbsp;
        </p>
        {serverResponse ? (
          <span className="mb-4 block text-red-500 mt-[-25px]">
            {serverResponse}
          </span>
        ) : null}
        <div className="flex flex-col gap-2">
          <Input
            style="primary"
            label="Email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email here..."
            value={email}
            inputId="email"
            name="email"
            required={true}
          />
          <Input
            style="primary"
            label="Password"
            type="password"
            placeholder="Enter your password here..."
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            inputId="password"
            name="password"
            required={true}
          />

          {!isLogin && (
            <Input
              style="primary"
              label="Confirm Password"
              type="password"
              placeholder="Enter your confirm password here..."
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              inputId="confirmPassword"
              name="confirmPassword"
            />
          )}

          {!isLogin && (
            <Checkbox
              style="primary"
              inputId="acceptedTerms"
              checked={acceptedTerms}
              moreWrapperStyles="mb-3"
              onChange={(e) => setAcceptedTerms(e.target.checked)}
              label={
                <>
                  I agree to the&nbsp;
                  <a
                    href="https://www.arcangelai.com/terms-conditions/"
                    className="text-blue-500"
                  >
                    Terms of Service
                  </a>
                </>
              }
            />
          )}
          <Button
            text={isLogin ? "Login" : "Sign Up"}
            style="primary"
            moreStyles="w-full mt-4"
            type="submit"
          />
          <Button
            text={isLogin ? "Switch to Sign Up" : "Switch to Login"}
            style="primary"
            moreStyles="w-full bg-transparent"
            onClick={() => setIsLogin(!isLogin)}
          />
        </div>
        <span className="block text-white text-xs mt-4 text-center">
          © 2023 ArcAngelAI - www.arcangelai.com
        </span>
      </form>
    </div>
  );
}
