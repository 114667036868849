import React, { useState } from "react";
import Button from "./Button";
import Modal from "./Modal";

function ReadMeButton() {
  const [open, setOpen] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        text="Guide"
        style="primary"
        moreStyles="w-full"
        type="submit"
        onClick={handleClickOpen}
      />
      <Modal
        open={open}
        handleClose={handleClose}
        handleOpen={handleClickOpen}
        title="Configuring your training data"
        footer={
          <Button
            text="Close"
            style="primary"
            type="submit"
            onClick={handleClose}
          />
        }
        content={
          <>
            <p className="text-sm text-white font-light">
              Configure your training data to have arrays of inputs and outputs.
              Limit 100 examples per training.
            </p>
            <pre className="text-[#2EFF5C] mt-5 p-5 rounded-xl bg-blue-900">
              <code>
                {JSON.stringify(
                  [
                    ["Hello", "How are you?"],
                    ["Hey!", "What's up?"],
                  ],
                  null,
                  2
                )}
              </code>
            </pre>
          </>
        }
      />
    </div>
  );
}

export default ReadMeButton;
