import { useState, useEffect } from "react";
import { Box, TextField, Typography, LinearProgress } from "@mui/material";
import { auth } from "../firebase";
import { grey } from "@mui/material/colors";
import Input from "./Input";

const apiCall = async (message) => {
  try {
    const response = await fetch("https://api.arcangelai.com/arc/v2", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        uid: auth.currentUser.uid,
        message: message,
        name: "genesis",
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.message;
  } catch (error) {
    alert(`An error occurred: ${error.message}`);
  }
};

const TestModelChat = () => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      messages.length > 0 &&
      messages[messages.length - 1].sender === "user"
    ) {
      setIsLoading(true);
      apiCall(messages[messages.length - 1].text)
        .then((response) => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: "api", text: response },
          ]);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [messages]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "user", text: input },
    ]);
    setInput("");
  };

  return (
    <>
      <div className="flex flex-col h-[200px]">
        <div className="overflow-auto flex flex-col-reverse grow">
          {messages.length > 0 ? (
            messages
              .slice(0)
              .reverse()
              .map((message, index) => (
                <p
                  key={index}
                  //   color={message.sender === "user" ? "primary" : "secondary"}
                  className={`${
                    message.sender === "user" ? "bg-blue-900" : "bg-transparent"
                  } p-2 px-5 text-white rounded-t-full rounded-l-full w-fit mb-1`}
                >
                  {message.text}
                </p>
              ))
          ) : (
            <p className="text-center text-xs text-white font-light mb-3">
              No messages yet.
            </p>
          )}
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <Input
              style="primary"
              type="text"
              onChange={(e) => setInput(e.target.value)}
              placeholder="Enter message..."
              value={input}
              inputId="message"
              name="message"
              moreStyles="border border-white py-3"
            />
          </form>
        </div>
      </div>
      {isLoading && <LinearProgress />}
    </>
  );
};

export default TestModelChat;
