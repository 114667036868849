import Auth from './pages/Auth';
import Home from './pages/Home';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './App.css';
import { useEffect, useState } from 'react';
import TopMenu from './components/TopMenu';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { onValue, ref } from 'firebase/database';
import { auth, db } from './firebase';
import { LinearProgress } from '@mui/material';


const stripePromise = loadStripe('pk_test_51N7yUDIIMb1VunXo4Kv2rwjQMFzopBwXW4vCzAYFOfEpQFeHszh99KE856FVHY1Krtt25EKSrZf4oEePLPjlRGTQ00YTYHTL2R');


function App() {
  const [user, setUser] = useState(null)
  const [account, setAccount] = useState(null)
  const [loading, setLoading] = useState(true)
  const [accountLoaded, setAccountLoaded] = useState(false)

  useEffect(()=>{
    const authObserver = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        // User is signed in
        setUser(firebaseUser);
      } else {
        // User is signed out
        setUser(null);
        setLoading(false); // if user is not signed in, we're not loading account data
      }
    });

    // Clean up observer on unmount
    return () => authObserver();
  }, [])

  useEffect(()=>{
    if(!user) return; // if there's no user, we don't load account data
    const userDetailsRef = ref(db, 'account/' + user.uid);
    const dbObserver = onValue(userDetailsRef, (snapshot) => {
      const data = snapshot.val();
      setAccount(data);
      setAccountLoaded(true); // account data is loaded
      setLoading(false); // we're not loading anymore
    });

    // Clean up observer on unmount
    return () => dbObserver();
  },[user]) // this effect depends on user

  if (loading) {
    return <div><LinearProgress /></div>;
  }

  return !user? <Auth user={user} /> :
  <>
      <TopMenu user={user} />
      <Elements stripe={stripePromise}>
        <Home user={user} account={account} />
      </Elements>
  </>
}

export default App;
