import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dropdown({
  items,
  choosed = null,
  onChange,
  moreStylesWrapper,
  moreStylesBox,
  icon,
  moreStyleItems,
  moreLabelStyles,
  label,
  required,
}) {
  const [selected, setSelected] = useState(choosed || items[0]);

  const handleChange = (val) => {
    onChange(val);
    setSelected(val);
  };

  return (
    <>
      {label && (
        <label
          className={`text-white text-sm font-medium mb-2 block ${moreLabelStyles}`}
        >
          {label} {required ? <span className="text-red-800">*</span> : ""}
        </label>
      )}
      <Menu
        as="div"
        className={`relative inline-block pt-1 text-left !mt-1  w-full !p-0 ${moreStylesWrapper}`}
      >
        <div>
          <Menu.Button
            className={`bg-text-primary inline-flex w-full items-center gap-x-1.5 rounded-md text-sm font-normal text-white shadow-sm  justify-between py-3 px-5 border-none ${moreStylesBox}`}
          >
            {icon}
            {selected?.value}
            <svg
              width="12"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L5.55806 5.55806C5.80214 5.80214 6.19786 5.80214 6.44194 5.55806L11 1"
                stroke="white"
                stroke-width="1.25"
                stroke-linecap="round"
              />
            </svg>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`bg-text-primary absolute right-0 z-10 mt-2  origin-top-right rounded-md text-white  bg-white-200 shadow-lg  focus:outline-none  w-full !mr-0 ${moreStyleItems}`}
          >
            <div className="py-1">
              {items?.length > 0 &&
                items.map((item) => (
                  <Menu.Item
                    key={`dropdown-${item.key}`}
                    onClick={() => handleChange(item)}
                  >
                    {({ active }) => (
                      <a
                        href="!#"
                        className={classNames(
                          active
                            ? "bg-primary text-white-100"
                            : "text-white dark:text-white-100",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        {item?.value}
                      </a>
                    )}
                  </Menu.Item>
                ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
